import React from "react"

import { Typography } from "@material-ui/core"
import { Helmet } from "react-helmet"

import MainShell from "../../components/main-shell"
import CheckoutStepper from "../../components/checkout-stepper"

const CartPage = () => {
  return (
    <MainShell>
      <Helmet>
        <title>Afgerond - Todocards.nl</title>
        <meta name="robots" content="noindex, follow" />
      </Helmet>

      <div className="mb2">
        <CheckoutStepper currentStep={4} />
      </div>

      <Typography variant="h1" gutterBottom>
        Afgerond
      </Typography>

      <Typography>
        De betaling is gelukt! We gaan direct voor je aan de slag en zullen je
        een e-mail sturen wanneer de bestelling verzonden is.
      </Typography>
    </MainShell>
  )
}

export default CartPage
