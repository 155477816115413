import React from "react"

import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import clsx from "clsx"

import { ViewProps } from "./checkout-stepper-types"
import checkIcon from "./check.svg"

const useStyles = makeStyles(theme => ({
  root: {
    display: `flex`,
    fontSize: `80%`,

    [theme.breakpoints.up(`sm`)]: {
      fontSize: `100%`,
    },
  },

  step: {
    display: `flex`,
    flexBasis: `25%`,
    alignItems: `center`,
    flexDirection: `column`,
  },

  stepIndicatorWrapper: {
    display: `flex`,
    width: `100%`,
  },

  stepIndicator: {
    width: `21px`,
    height: `21px`,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: `50%`,
    position: `relative`,
    marginBottom: theme.spacing(0.5),

    [theme.breakpoints.up(`sm`)]: {
      width: `31px`,
      height: `31px`,
    },

    "&.disabled": {
      backgroundColor: theme.palette.grey[500],
    },
  },

  stepIndicatorInner: {
    position: `absolute`,
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,

    "&.number": {
      color: theme.palette.secondary.contrastText,
      fontWeight: 500,
    },

    "&.icon": {
      width: `50%`,
    },
  },

  spacer: {
    marginTop: `10px`,
    height: `1px`,
    flexGrow: 1,
    backgroundColor: theme.palette.secondary.main,

    [theme.breakpoints.up(`sm`)]: {
      marginTop: `15px`,
    },

    "&.disabled": {
      backgroundColor: theme.palette.grey[500],
    },
  },

  disabledLabel: {
    color: theme.palette.grey[500],
  },
}))

const CheckoutStepperView = ({ currentStep }: ViewProps) => {
  const classes = useStyles()
  const steps = [`Winkelwagen`, `Gegevens`, `Betalen`, `Afgerond`]

  return (
    <Typography component="div" variant="body2" className={classes.root}>
      {steps.map((label, i) => {
        const step = i + 1
        return (
          <div className={classes.step} key={step}>
            <div className={classes.stepIndicatorWrapper}>
              <div
                className={clsx(
                  classes.spacer,
                  step > currentStep && `disabled`
                )}
              />

              <div
                className={clsx(
                  classes.stepIndicator,
                  step > currentStep && `disabled`
                )}
              >
                {step < currentStep || currentStep === steps.length ? (
                  <img
                    src={checkIcon}
                    alt={`Stap ${step}`}
                    className={clsx(classes.stepIndicatorInner, `icon`)}
                  />
                ) : (
                  <div className={clsx(classes.stepIndicatorInner, `number`)}>
                    {step}
                  </div>
                )}
              </div>

              <div
                className={clsx(
                  classes.spacer,
                  step >= currentStep &&
                    currentStep !== steps.length &&
                    `disabled`
                )}
              />
            </div>

            <div
              className={clsx(
                step > currentStep && classes.disabledLabel,
                step === currentStep && currentStep !== steps.length && `bold`
              )}
            >
              {label}
            </div>
          </div>
        )
      })}
    </Typography>
  )
}

export default CheckoutStepperView
